// This is HomePage.jsx
import React, { useEffect } from "react";
import { AuthState } from "../../context/AuthProvider";
import { Notify } from "../../utils";
import FindPatient from './findpt';
import UserStatusComponent from './requeststatus'
import DefaultSidebar from "../../components/sidebar"




const HomePage = () => {
  const { auth } = AuthState();
  const fetchPrivateDate = async () => {
    try {
      const response = await fetch("/api/private", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const data = await response.json();

      if (!data.success) {
        // setIsAuthenticated(false);
        Notify("You are not authorized please login", "error");
        
      }
    } catch (error) {
      localStorage.removeItem("auth");
      // setIsAuthenticated(false);
      Notify("Internal server error", "error");
      
    }
  };

  useEffect(() => {
    fetchPrivateDate();
    // eslint-disable-next-line
  }, []);


  return (
    <div className="flex flex-row min-h-screen bg-gray-50 w-full">
      <aside className="w-1/5 bg-blue-100 border-r border-gray-200 float-left rounded-r-3xl">
        <DefaultSidebar />
      </aside>
      <main className="flex-1">
        <div className="p-8 w-full">
          <FindPatient />
          <UserStatusComponent />
        </div>
      </main>
    </div>
  );
};

export default HomePage;
