import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthState } from "../context/AuthProvider";
import DefaultSidebar from "../components/sidebar"



const PatientList = () => {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { auth } = AuthState();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/api/user/allpt', {
            main_account: auth.bdsk
        }, {
          headers: { Authorization: `Bearer ${auth.token}` }
        });
        setPatients(response.data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [auth.bdsk, auth.token]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPatients = patients.filter(patient =>
    Object.values(patient).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="flex h-screen">
      <DefaultSidebar className="w-1/4 bg-gray-100 shadow-xl" />
      <div className="w-3/4 container mx-auto p-6">
        <input
          type="text"
          placeholder="Search..."
          onChange={handleSearch}
          className="mb-4 p-2 border border-gray-300 rounded shadow"
        />
        <div>
          {filteredPatients.map((patient, index) => (
            <div key={index} className="bg-gray-50 shadow-lg rounded p-4 mb-4">
              <h2 className="text-lg font-bold">{patient.name}</h2>
              <p>Phone: {patient.phoneNumber}</p>
              <p>Providers: {patient.providers}</p>
              <p>Visit Type: {patient.typeOfVisit}</p>
              <p>Dates: {patient.selectedDates.join(', ')}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatientList;
