// This is FindPatient.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './findpt.css';
import { AuthState } from "../../context/AuthProvider";
 
const FindPatient = () => {
    const today = new Date().toISOString().split('T')[0];
    const defaultTime = { hour: '08', minute: '00', period: 'AM' };
    const { auth } = AuthState();
    const [time, setTime] = useState(defaultTime);
    const [date, setDate] = useState(today);
    const [provider, setProvider] = useState('');
    const [clinic, setClinic] = useState('');
    const [headerLogo, setHeaderLogo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    // New States for Type of Visit and Providers
    const [typeOfVisit, setTypeOfVisit] = useState('');
    const [typeOfVisitOptions, setTypeOfVisitOptions] = useState([]);
    const [providers, setProviders] = useState([]);
    const [filteredProviders, setFilteredProviders] = useState([]);

  
  
    // Updated styles for the form
    const formContainerStyle = "bg-white shadow-lg rounded-lg p-8 my-8 mx-auto max-w-3xl";
    const formSectionStyle = "grid grid-cols-1 md:grid-cols-3 gap-4 items-center mb-6";
    const labelStyle = "block text-sm font-semibold text-gray-700 md:col-span-1";
    const inputContainerStyle = "md:col-span-2";
    const inputStyle = "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-0 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm";
    const buttonContainerStyle = "flex justify-center items-center mt-4"; // Added top margin
    const buttonStyle = "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"; // Reduced padding for smaller size


  // Fetch providers and clinics on component mount
    // Fetch providers and clinics on component mount
    useEffect(() => {
        const fetchCustomScript = async () => {
          try {
            const response = await axios.post('/api/user/customscript', {
              username: auth.bdsk
            }, {
              headers: { Authorization: `Bearer ${auth.token}` }
            });
            const data = response.data;
            setHeaderLogo(data.header_logo);
  
                // Handling Providers
                if (data.providers) {
                    setProviders(data.providers); // Set the complete providers array
                    const initialFilteredProviders = data.providers.filter(provider => 
                        provider.typeOfVisits.includes(data.typeOfVisitOptions[0])
                    );
                    setFilteredProviders(initialFilteredProviders); // Initialize with filtered providers
                    if (initialFilteredProviders.length > 0) {
                        setProvider(initialFilteredProviders[0].name); // Set the first filtered provider as default
                    }
                }
  
            // Handling Type of Visit
            if (data.typeOfVisitOptions) {
                setTypeOfVisitOptions(data.typeOfVisitOptions);
                setTypeOfVisit(data.typeOfVisitOptions[0]);
            }
            if (data.clinics) {
                setClinic(data.clinics);
            }
            
            console.log("Fetched typeOfVisitOptions:", data.typeOfVisitOptions);
            console.log("Fetched providers:", data.providers);
          } catch (error) {
            console.error('Error fetching custom script:', error);
          }
        };
  
        fetchCustomScript();
      }, [auth.bdsk, auth.token]);
  




    // Function to handle Type of Visit change
    const handleTypeOfVisitChange = (e) => {
        const selectedType = e.target.value;
        setTypeOfVisit(selectedType);

        const filtered = providers.filter(provider => 
            provider.typeOfVisits.includes(selectedType) || selectedType === 'ALL'
        );
        console.log("Here are filtred lsit", filtered[0].name)
        setFilteredProviders(filtered);
        if (filtered.length > 0) {
            setProvider(filtered[0].name); // Set the first provider name as default
        } else {
            setProvider(''); // Reset the provider if no match is found
        }
    };


    const findPatient = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const appointmentDate = new Date(date);
        appointmentDate.setMinutes(appointmentDate.getMinutes() + appointmentDate.getTimezoneOffset());
        const day = appointmentDate.toLocaleString('en-us', { weekday: 'long' });
    
        // Formatting the date to MM/DD/YYYY
        const formattedDate = (appointmentDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
                              appointmentDate.getDate().toString().padStart(2, '0') + '/' +
                              appointmentDate.getFullYear();
    
        const cancelTime = `${time.hour}:${time.minute} ${time.period}`;
    
        const requestBody = {
          Cancel_Time: cancelTime,
          DateofApt: formattedDate, // Using the formatted date
          day: day,
          Provider: provider,
          clinic: clinic,
          main_account: auth.bdsk, // Using auth.bdsk directly
          typeOfVisit: typeOfVisit
        };
        console.log(requestBody)

      

    try {
        const response = await axios.post('/api/user/findpt', requestBody, {
            headers: { Authorization: `Bearer ${auth.token}` }
        });
        if (response.status === 200) {
            setIsSuccess(true);
            setTimeout(() => {
                // Reset the form after showing success
                resetForm();
            }, 2000); // Adjust time as needed
        }
    } catch (error) {
        console.error('Error finding patient:', error);
        setIsLoading(false); // Reset loading state on error
    }
};

    // Reset form to default values
    const resetForm = () => {
        setIsLoading(false);
        setIsSuccess(false);
        setDate(today);
        setProvider(filteredProviders.length > 0 ? filteredProviders[0].name : '');
        setTime(defaultTime);
    };
        // Time dropdown options (optional: you can adjust the range as needed)
        const hourOptions = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
        const minuteOptions = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
    

        if (isLoading) {
            return (
              <div className="flex h-screen items-center justify-center">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
              </div>
            );
          }

if (isSuccess) {
    return (
        <div>
            <p>Success <span>✔️</span></p>
            {/* Optionally add a loading indicator here */}
        </div>
    );
}


return (
    <div className={formContainerStyle}>
        {headerLogo && <img src={headerLogo} alt="Header Logo" className="mx-auto w-32 h-auto mb-8"/>}

        <h3 className="text-xl leading-6 font-bold text-gray-900 text-center mb-10">Patient Finder</h3>

        <form>
            <div className={formSectionStyle}>
                <label htmlFor="date" className={labelStyle}>Date</label>
                <div className={inputContainerStyle}>
                <input type="date" id="date" value={date} onChange={(e) => setDate(e.target.value)} className={inputStyle}/>
                </div>
            </div>

            {/* {providersOptions.length > 0 && (
                <div className={formSectionStyle}>
                    <label htmlFor="provider" className={labelStyle}>Provider</label>
                    <select id="provider" value={provider} onChange={(e) => setProvider(e.target.value)} className={inputStyle}>
                        {providersOptions.map((provider, index) => (
                            <option key={index} value={provider}>{provider}</option>
                        ))}
                    </select>
                </div>
            )} */}

            {/* {clinicOptions.length > 0 && (
                <div className={formSectionStyle}>
                    <label htmlFor="clinic" className={labelStyle}>Clinic</label>
                    <select id="clinic" value={clinic} onChange={(e) => setClinic(e.target.value)} className={inputStyle}>
                        {clinicOptions.map((clinic, index) => (
                            <option key={index} value={clinic}>{clinic}</option>
                        ))}
                    </select>
                </div>
            )} */}

            <div className={`${formSectionStyle} flex justify-center`}>
                <label htmlFor="cancel-time" className={labelStyle}>Cancel Time</label>
                <div className="mt-1 flex rounded-md ">
                    
                    <select id="hour" value={time.hour} onChange={(e) => setTime({ ...time, hour: e.target.value })} className={inputStyle}>
                        {hourOptions.map(hour => <option key={hour} value={hour}>{hour}</option>)}
                    </select>
                    
                    <span className="inline-flex items-center px-1 rounded-r-md text-gray-500 sm:text-sm">:</span>
                    <select id="minute" value={time.minute} onChange={(e) => setTime({ ...time, minute: e.target.value })} className={inputStyle}>
                        {minuteOptions.map(minute => <option key={minute} value={minute}>{minute}</option>)}
                    </select>
                    <span className="p-1"></span>
                    <select id="period" value={time.period} onChange={(e) => setTime({ ...time, period: e.target.value })} className={inputStyle}>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                    
                </div>
            </div>

                {/* Type of Visit Selection */}
                <div className={formSectionStyle}>
                <label htmlFor="type-of-visit" className={labelStyle}>Type of Visit</label>
                <div className={inputContainerStyle}>
                <select id="type-of-visit" value={typeOfVisit} onChange={handleTypeOfVisitChange} className={inputStyle}>
                    {typeOfVisitOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </select>
                </div>
            </div>

                {/* Provider Selection Based on Type of Visit */}
                <div className={formSectionStyle}>
                    <label htmlFor="provider" className={labelStyle}>Provider</label>
                    <div className={inputContainerStyle}>
                        <select id="provider" value={provider} onChange={(e) => setProvider(e.target.value)} className={inputStyle}>
                            {filteredProviders.map((filteredProvider, index) => (
                                <option key={index} value={filteredProvider.name}>{filteredProvider.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

            <div className={buttonContainerStyle}>
                <button type="button" onClick={findPatient} className={buttonStyle}>
                    Find Patient
                </button>
            </div>
        </form>
    </div>
);
};

export default FindPatient;