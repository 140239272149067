// AddPatientForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from './Calendar';
import { format } from 'date-fns';


const AddPatientFormPage = () => {
        // const [selectedDates, setSelectedDates] = useState([]);
      
 

    // const [selectedDates, setSelectedDates] = useState([]);
    const [submissionStatus, setSubmissionStatus] = useState({
        isSuccess: false,
        isError: false,
    });
    const [dateSelectionRequired, setDateSelectionRequired] = useState(false);
    const [shakeEffect, setShakeEffect] = useState(false);




    const formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handlePhoneNumberChange = (event) => {
        const formattedPhoneNumber = formatPhoneNumber(event.target.value);
        setFormData({ ...formData, phoneNumber: formattedPhoneNumber });
    };

    
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [userData, setUserData] = useState({});
    const [providers, setProviders] = useState([]);
    const [typeOfVisitOptions, setTypeOfVisitOptions] = useState([]);
    const [loading, setLoading] = useState(false); // New state for loading
    
    const [SuccessMessage, setSuccessMessage] = useState(false); 
    const [ErrorMessage, setErrorMessage] = useState(false); 
    const [is404Error, setIs404Error] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        countryCode: '+1',
        clinic: '',
        provider: '',
        typeOfVisit: '',
        selectedDates: [],
    });
    const [urlHash, setUrlHash] = useState(null);


    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true); // Start loading before API requests
            try {
                const path = window.location.pathname;
                const urlParam = path.split('/newrequest-')[1] || '';
                const findUserResponse = await axios.post('/api/user/finduser', { url: urlParam });
                const username = findUserResponse.data.user;

                if (!username) {
                    setIs404Error(true);
                    setLoading(false); // Stop loading if no user found
                    return;
                }

                const customScriptResponse = await axios.post('/api/user/customscript', { username }, {
                    headers: { 'Authorization': 'HGGShinchan' }
                });

                const data = customScriptResponse.data;
                setUserData(data);
                setTypeOfVisitOptions(data.typeOfVisitOptions || []);
                setProviders(data.providers || []);
                setFormData(prevState => ({
                    ...prevState,
                    clinic: data.clinics[0] || '',
                    provider: data.providers[0]?.name || '',
                    typeOfVisit: data.typeOfVisitOptions[0]|| '', 
                }));
                document.title = data.title || 'Add Patient';
            } catch (error) {
                console.error('Error fetching user data:', error);
                setIs404Error(true);
            } finally {
                setLoading(false); // Stop loading after API requests are done
            } 
            const urlHash = window.location.hash.substring(1); // Gets the substring after '#'
            setUrlHash(urlHash);
        };

        fetchUserData();
    }, []); // Removed dependencies to only run once on component mount

        // Modify the form data based on the URL hash
    useEffect(() => {
        if (urlHash) {
            // Set clinic to the default value and provider to "ALL"
            setFormData(prevState => ({
                ...prevState,
                clinic: userData.clinics[0] || '',
                provider: 'ALL',
                typeOfVisit: userData.typeOfVisitOptions[parseInt(urlHash) - 1] || ''
            }));
        }
    }, [urlHash, userData]);

    // Conditional rendering of form fields
    const renderClinicAndProviderFields = !urlHash;



    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(formData => ({ ...formData, [name]: value }));
    
        if (name === 'typeOfVisit') {
            // Filter providers based on selected type of visit
            let filteredProviders;
            if (value === 'ALL') {
                filteredProviders = userData.providers;
            } else {
                filteredProviders = userData.providers.filter(provider => 
                    provider.typeOfVisits.includes(value)
                );
            }
            setProviders(filteredProviders);
            setFormData(formData => ({ ...formData, provider: '' })); // Reset provider selection
        }
    };


    // const handleDateChange = useCallback((dates) => {
    //     console.log("Selected dates in parent component:", dates);
    //     const formattedDates = dates.map(date => format(date, 'MM/dd/yyyy'));
    //     setFormData({ ...formData, selectedDates: formattedDates });
    // });
    
    const handleDateChange = (dates) => {
        console.log("Selected dates in parent component:", dates);
        const formattedDates = dates.map(date => format(date, 'MM/dd/yyyy'));
        setFormData({ ...formData, selectedDates: formattedDates });
        if (dates.length > 0) {
            setDateSelectionRequired(false);
            setShakeEffect(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Check if dates are selected
        if (formData.selectedDates.length === 0) {
            setDateSelectionRequired(true);
            setShakeEffect(true);
            setTimeout(() => setShakeEffect(false), 820); // Reset shake effect
            return; // Prevent form submission
        }
    
        const mergedPhoneNumber = `${formData.countryCode.replace('+', '')}${formData.phoneNumber.replace(/[^\d]/g, '')}`;
    
        // Prepare data to send
        let providersToSend = formData.provider === 'ALL' 
                               ? providers.map(provider => provider.name) 
                               : [formData.provider];
    
        let dataToSend = {
            name: formData.name,
            phoneNumber: mergedPhoneNumber, // Merge country code with phone number
            countryCode: formData.countryCode,
            clinic: formData.clinic,
            typeOfVisit: formData.typeOfVisit,
            selectedDates: formData.selectedDates,
            main_account: userData.username, // Replace with the username from userData
            providers: providersToSend // Send providers as an array
        };
    
        console.log(dataToSend);
        setLoading(true);
    
        try {
            const response = await axios.post('/api/user/addpt', dataToSend, {
                headers: { 'Authorization': 'HGGShinchan' }
            });
            if (response.status === 200) {
                setFormSubmitted(true);
                setSubmissionStatus({ isSuccess: true, isError: false });
            }
            setLoading(false); // End loading
            setSuccessMessage(true);
        } catch (error) {
            console.error('Error submitting form', error);
            setSubmissionStatus({ isSuccess: false, isError: true });
            setLoading(false); // End loading
            setErrorMessage(true);
        }
    };
    
    
    
    


    if (loading) {
        return (
        <div className="flex h-screen items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
        );
    }

    if (SuccessMessage) {
        return (

            <div className="bg-white p-10 rounded-lg shadow-md text-center w-full max-w-md mx-auto">
            <div class="text-4xl text-green-500">✔</div>
            <h1 className="text-2xl font-semibold text-gray-800 mt-4">Confirmation</h1>
            <p className="text-gray-600 mt-4">You've been successfully subscribed. We'll notify you with updates.</p>
            <a href="https://scheduleclinic.com" className="mt-6 inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
            Explore our Website 
            </a>
           </div>
        );
    };

    if (ErrorMessage) {
        return (
            <div className="bg-white p-10 rounded-lg shadow-md text-center w-full max-w-md mx-auto">
            <div className="text-6xl text-red-500">✖</div>
            <h1 className="text-2xl font-semibold text-gray-800 mt-4">Error</h1>
            <p className="text-gray-600 mt-4">Something went wrong, contact the office for help.</p>
            <a href="https://scheduleclinic.com" className="mt-6 inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
            Return to Home
            </a>
            </div>
        );
    };


    return (
        <div className="container mx-auto p-4">
            {userData.header_logo && (
                <img src={userData.header_logo} alt="Header Logo" className="mx-auto h-48" />
            )}
            {is404Error ? (
                       <div className="flex h-screen w-full items-center justify-center bg-red-100">
                       <div className="text-red-800 bg-red-300 border border-red-500 shadow-lg rounded p-6">
                           <span className="text-5xl text-red-600">✖</span>
                           <p className="text-2xl">Incorrect URL. Please check the URL.</p>
                       </div>
                   </div>
            ) : (
                <>
                    <h1 className="text-3xl font-bold text-center my-4">{userData.title}</h1>
                    {!formSubmitted ? (
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div className="flex flex-col"> 
                                <label htmlFor="name" className="text-lg font-semibold">Name <span className="text-red-500">*</span></label>
                                <input type="text" id="name" name="name" onChange={handleInputChange} className="border rounded p-2" required />
                            </div>

                            <div className="flex flex-col">
                                <label htmlFor="phone_number" className="text-lg font-semibold">Phone Number <span className="text-red-500">*</span></label>
                                <div className="flex">
                                    <select name="countryCode" onChange={handleInputChange} className="border rounded p-2 mr-2 w-20" required>
                                        <option value="+1">+1</option>
                                    </select>
                                    <input type="text" id="phone_number" name="phoneNumber" value={formData.phoneNumber} onChange={handlePhoneNumberChange} className="border rounded p-2 flex-grow" pattern="\(\d{3}\) \d{3}-\d{4}" placeholder="(123) 456-7890" required />
                                </div>
                            </div>
                                {/* New Type of Visit dropdown */}
                                <div className="flex flex-col">
                                <label htmlFor="typeOfVisit" className="text-lg font-semibold">Type of Visit: </label>
                                {urlHash ? (
                                    <div className="text-lg">
                                        {userData.typeOfVisitOptions[parseInt(urlHash) - 1] || 'Not available'}
                                    </div>
                                ) : (
                                    <select id="typeOfVisit" name="typeOfVisit" value={formData.typeOfVisit} onChange={handleInputChange} className="border rounded p-2" required>
                                        {typeOfVisitOptions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                )}
                                </div>

                            {/* Conditional rendering based on URL hash */}
                            {renderClinicAndProviderFields && (
                                <>
                                <div className="flex flex-col">
                                    <label htmlFor="clinic" className="text-lg font-semibold">Clinic</label>
                                    <select id="clinic" name="clinic" value={formData.clinic} onChange={handleInputChange} className="border rounded p-2" required>
                                        {userData.clinics && userData.clinics.map((clinic, index) => (
                                            <option key={index} value={clinic.trim()}>{clinic}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex flex-col">
                                <label htmlFor="provider" className="text-lg font-semibold">Provider</label>
                                <select id="provider" name="provider" value={formData.provider} onChange={handleInputChange} className="border rounded p-2" required>
                                    <option value="">Select Provider</option> {/* Optional: Default option */}
                                    <option value="ALL">ALL</option> {/* Added ALL option */}
                                    {providers.map((provider) => (
                                        <option key={provider._id} value={provider.name}>{provider.name}</option>
                                    ))}
                                </select>
                                </div>
                                </>
                            )}

                            {/* Calendar component */}
                            {/* Calendar component with responsive styling */}
                            {/* Calendar component with shake effect */}
                            <div className={`flex flex-col mb-4 ${shakeEffect ? 'animate-shake' : ''}`}>
                            {dateSelectionRequired && (
                        <h2 className="text-red-500 text-center text-2xl font-bold">Please select dates by clicking!</h2>
                    )}
                    <Calendar onDatesChange={handleDateChange} />
                </div>

                            {/* Submit button */}
                            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Submit
                            </button>
                        </form>
                    ) : (
                        <>
                            {submissionStatus.isSuccess && (
                            <div className="bg-white p-10 rounded-lg shadow-md text-center w-full max-w-md mx-auto">
                                <div className="text-6xl text-green-500">✔</div>
                                <h1 className="text-2xl font-semibold text-gray-800 mt-4">Confirmation</h1>
                                <p className="text-gray-600 mt-4">You've been successfully subscribed. We'll notify you with updates.</p>
                                <a href="https://scheduleclinic.com" className="mt-6 inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
                                Explore our Website 
                                </a>
                            </div>
                            )}


                                {submissionStatus.isError && (
                                <div className="bg-white p-10 rounded-lg shadow-md text-center w-full max-w-md mx-auto">
                                    <div className="text-6xl text-red-500">✖</div>
                                    <h1 className="text-2xl font-semibold text-gray-800 mt-4">Error</h1>
                                    <p className="text-gray-600 mt-4">Something went wrong, contact the office for help.</p>
                                    <a href="https://scheduleclinic.com" className="mt-6 inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
                                    Return to Home
                                    </a>
                                </div>
                                )}

                        </>
                    )}
                </>
                
            )}
        </div>
        
    );
};

export default AddPatientFormPage;