// This is requeststatus.js
import React, { useEffect, useState, useCallback } from 'react';
import { AuthState } from "../../context/AuthProvider";
import axios from 'axios';
import { Card, Pagination, Button } from 'react-bootstrap';
import { Tab } from "@headlessui/react";
import { TrashIcon } from '@heroicons/react/24/solid';


const UserStatusComponent = () => {
    const { auth } = AuthState();
    const [data, setData] = useState([]);
    const [currentPageOpen, setCurrentPageOpen] = useState(1);
    const [currentPageReserved, setCurrentPageReserved] = useState(1);
    const [itemsPerPage] = useState(5);
    const [currentPageClosed, setCurrentPageClosed] = useState(1);

    const containerStyle = "max-w-5xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden";
    const tabListStyle = "flex p-1 space-x-1 bg-blue-900/20 rounded-t-lg";
    const tabPanelStyle = "p-5";

    const fetchData = useCallback(async () => {
        try {
            const response = await axios.post('/api/user/status', {
                username: auth.bdsk
            }, {
                headers: { Authorization: `Bearer ${auth.token}` }
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }, [auth.bdsk, auth.token]);

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 5000);
        return () => clearInterval(interval);
    }, [fetchData]);

    const paginateOpen = (pageNumber) => setCurrentPageOpen(pageNumber);
    const paginateReserved = (pageNumber) => setCurrentPageReserved(pageNumber);
    const paginateClosed = (pageNumber) => setCurrentPageClosed(pageNumber);

    // Function to parse date in the format "MM/DD/YYYY, HH:MM:SS AM/PM"
    const parseDate = (dateString) => {
        const [datePart, timePart] = dateString.split(', ');
        const [month, day, year] = datePart.split('/');
        const [time, modifier] = timePart.split(' ');
        let [hours, minutes, seconds] = time.split(':');
        
        if (modifier === 'PM' && hours !== '12') {
            hours = parseInt(hours, 10) + 12;
        }
        if (modifier === 'AM' && hours === '12') {
            hours = '00';
        }

        return new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hours}:${minutes}:${seconds}`);
    };

    // Sort and filter data for each status
    const sortDataByDate = (data, status) => {
        return data.filter(item => item.status === status)
                   .sort((a, b) => parseDate(b.createdAt) - parseDate(a.createdAt));
    };

    // Function to change status to 'closed'
    const changeStatusToClosed = async (box, id, confirmedpatientName1, confirmedpatientPhoneNumber1) => {
        try {
            await axios.post('/api/user/changestatus', {
                box,
                id,
                confirmedpatientName1,
                confirmedpatientPhoneNumber1,
                status: 'closed'
            }, {
                headers: { Authorization: `Bearer ${auth.token}` }
            });

            // Refresh data after status change
            fetchData();
        } catch (error) {
            console.error('Error changing status', error);
        }
    };

        // Function to change status to 'closed'
        const changeStatusToDelete = async (box, id) => {
            try {
                await axios.post('/api/user/changestatus', {
                    box,
                    id,
                    status: 'delete'
                }, {
                    headers: { Authorization: `Bearer ${auth.token}` }
                });
    
                // Refresh data after status change
                fetchData();
            } catch (error) {
                console.error('Error changing status', error);
            }
        };


    const filteredOpen = sortDataByDate(data, 'open');
    const filteredReserved = sortDataByDate(data, 'reserved');
    const filteredClosed = sortDataByDate(data, 'closed');

    // Paginate data for each tab
    const lastPageIndexOpen = currentPageOpen * itemsPerPage;
    const firstPageIndexOpen = lastPageIndexOpen - itemsPerPage;
    const currentItemsOpen = filteredOpen.slice(firstPageIndexOpen, lastPageIndexOpen);

    const lastPageIndexReserved = currentPageReserved * itemsPerPage;
    const firstPageIndexReserved = lastPageIndexReserved - itemsPerPage;
    const currentItemsReserved = filteredReserved.slice(firstPageIndexReserved, lastPageIndexReserved);

    // Pagination for closed tab
    const lastPageIndexClosed = currentPageClosed * itemsPerPage;
    const firstPageIndexClosed = lastPageIndexClosed - itemsPerPage;
    const currentItemsClosed = filteredClosed.slice(firstPageIndexClosed, lastPageIndexClosed);

     

    // Pagination controls for each tab
    const getPagination = (totalItems, currentPage, paginateFunction) => {
        const pageCount = Math.ceil(totalItems / itemsPerPage);
        let items = [];
        for (let number = 1; number <= pageCount; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => paginateFunction(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        return <Pagination>{items}</Pagination>;
    };

        const renderCard = (item, index) => {
            // Convert UTC to Eastern Standard Time (EST)
            const utcDate = new Date(item.createdAt);
            const estDate = new Date(utcDate.getTime()); // UTC - 5 hours for EST

            // Format the date to a 12-hour format
            const formattedDate = estDate.toLocaleString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                // second: 'numeric',
                // hour12: true,
                timeZone: 'America/New_York'
            });

            // Construct a unique key
            const uniqueKey = `${item.createdAt}-${item.patientInterestedId || 'NA'}-${index}`;

            return (
                
                <Card key={uniqueKey} className="mb-3">
                    <Card.Body>
                        <Card.Title>{item.provider} - {item.clinic}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">Time: {item.time}, Date: {item.date}</Card.Subtitle>
                        <Card.Text>
                            Status: {item.status}<br />
                            Created On: {formattedDate} EST <br />
                            Type Of Visit: {item.typeOfVisit}<br />
                            {/* Additional details for 'reserved' status */}
                            {item.status === 'closed' && (
                                <>
                                    Patient Name: {item.confirmedpatientName}<br />
                                    Patient Phone Number: {item.confirmedpatientPhoneNumber}<br />
                                </>
                            )}
                            {item.status === 'reserved' && (
                                <>
                                    Patient Name: {item.patientName}<br />
                                    Patient Phone Number: {item.patientPhoneNumber}<br />
                                    Patient Clinic: {item.patientClinic}<br />
                                    Patient Provider: {item.provider}<br />
                                    
                                    <Button 
                                    onClick={() => changeStatusToClosed(auth.bdsk, item.id, item.patientName, item.patientPhoneNumber )}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline float-right"
                                    >
                                    Appointment Confirmed
                                    </Button>
                                    <Button 
    onClick={() => changeStatusToDelete(auth.bdsk, item.id)}
    className="text-red-500 p-1 rounded focus:outline-none absolute top-0 right-0"
    style={{ border: 'none' }}
>
    <TrashIcon className="h-4 w-4" />
</Button>


                                </>
                                
                            )}
                        </Card.Text>
                    </Card.Body>
                </Card>
            );

                
        };
            // // Render reserved status cards
            // const renderReservedCards = () => {
            //     return filteredReserved.map((item, index) => renderCard(item, index));
            // };

            return (
                <div className={containerStyle}>
                <Tab.Group>
                  <Tab.List className={tabListStyle}>
                    <Tab as="button" className={({ selected }) => `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg ${selected ? 'bg-white shadow' : 'text-blue-500 hover:bg-white/[0.12] hover:text-white'}`}>
                      Pending Approval
                    </Tab>
                    <Tab as="button" className={({ selected }) => `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg ${selected ? 'bg-white shadow' : 'text-blue-500 hover:bg-white/[0.12] hover:text-white'}`}>
                      Open Spots
                    </Tab>
                    <Tab as="button" className={({ selected }) => `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg ${selected ? 'bg-white shadow' : 'text-blue-500 hover:bg-white/[0.12] hover:text-white'}`}>
                      Closed
                    </Tab>
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel className={tabPanelStyle}>
                      {currentItemsReserved.map((item, index) => renderCard(item, index))}
                      {getPagination(filteredReserved.length, currentPageReserved, paginateReserved)}
                    </Tab.Panel>
                    <Tab.Panel className={tabPanelStyle}>
                      {currentItemsOpen.map((item, index) => renderCard(item, index))}
                      {getPagination(filteredOpen.length, currentPageOpen, paginateOpen)}
                    </Tab.Panel>
                    <Tab.Panel className={tabPanelStyle}>
                      {currentItemsClosed.map((item, index) => renderCard(item, index))}
                      {getPagination(filteredClosed.length, currentPageClosed, paginateClosed)}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
                </div>
              );
};

export default UserStatusComponent;




