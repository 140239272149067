import { Routes, Route  } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect } from 'react';

import "react-toastify/dist/ReactToastify.min.css"; // CSS for "react-toastify"
import "./page.css"
// Private route
import { PrivateRoutes } from "./utils";

import AddPatientForm from "./pages/AddPatientForm"   
 
// Pages
import {
  HomePage,
  LoginPage,
  ForgotPasswordPage,
  PasswordResetPage,
  RegisterPage

} from "./pages";
// import IframePage from './IframePage';
import CustomPage from "./pages/HomePage/CustomPage";
// import DefaultSidebar from "./components/sidebar";
import PatientList from "./pages/PatientList";

const Redirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // Renders nothing, just performs the redirect
};


const App = () => {
  // const location = useLocation();


    // Determine if the current page is one of the specified routes
    // const showSidebar = location.pathname === '/' || location.pathname === '/custom';


  return (
    <>
    {/* {!isAddPatientFormPage && <NavigationBar />} */}
    <div className="main-layout">


      {/* {showSidebar && <DefaultSidebar />} Render Sidebar conditionally */}

      <Routes>
        {/* Private routes (Requires authentication token) */}
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<HomePage />} className="main-content" />
          <Route path="/custom" element={<CustomPage />} />
          <Route path="/list" element={<PatientList />} />
        </Route>

        {/* Public routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
        <Route
          path="/passwordReset/:resetToken"
          element={<PasswordResetPage />}
        />

        {/* AddPatientForm route */} 
        <Route path="/newrequest-:urlParam" element={<AddPatientForm />} />


        {/* If the user enters an invalid path in the URL it automatically redirects them to the homepage */}
        {/* <Route path="*" element={<IframePage src="https://home.scheduleclinic.com/" />} /> */}
        <Route path="*" element={<Redirect to="https://home.scheduleclinic.com/" />} />
      </Routes>
 
      {/* Remember to render the ToastContainer once in your application tree. Rendering it in the application root would be the best bet */}
      <ToastContainer />
      </div>

    </>
  );
};

export default App;
