// This is sidebar.js
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { PresentationChartBarIcon, DocumentTextIcon, PowerIcon, ListBulletIcon } from "@heroicons/react/24/solid";

// import { AuthState } from "../context/AuthProvider"; // Adjust the path as needed


const  DefaultSidebar = () =>  { 
  const navigate = useNavigate();
//   const { auth, setAuth } = AuthState(); // Using context to manage auth state

  const handleLogout = () => {
      localStorage.removeItem("auth");
        //   setAuth(null);
      navigate("/");
  };




  return (
    <div className="flex flex-col h-full bg-gray-50 shadow-lg">
      <div className="flex items-center justify-center p-4 bg-white border-b border-gray-200">
        <img src="/sidebarno.png" alt="Logo" className="h-12" />
      </div>
      <nav className="flex flex-col mt-4">
        <Link to="/dashboard" className="flex items-center p-3 text-base font-medium text-gray-900 rounded-md hover:bg-blue-100">
          <PresentationChartBarIcon className="w-6 h-6 text-blue-500" />
          <span className="ml-3">Dashboard</span>
        </Link>
        <Link to="/custom" className="flex items-center p-3 text-base font-medium text-gray-900 rounded-md hover:bg-blue-100">
          <DocumentTextIcon className="w-6 h-6 text-green-500" />
          <span className="ml-3">Form Builder</span>
        </Link>
        <Link to="/list" className="flex items-center p-3 text-base font-medium text-gray-900 rounded-md hover:bg-blue-100">
          <ListBulletIcon className="w-6 h-6 text-green-500" />
          <span className="ml-3">All Patients</span>
        </Link>
        <button onClick={handleLogout} className="flex items-center p-3 text-base font-medium text-gray-900 rounded-md hover:bg-blue-100">
          <PowerIcon className="w-6 h-6 text-red-500" />
          <span className="ml-3">Log Out</span>
        </button>
      </nav>
    </div>
  );
}


export default DefaultSidebar;