// Calendar.js
import React, { useState, useEffect } from 'react';
import { format, addDays, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isWithinInterval, isSameDay } from 'date-fns';

const Calendar = ({ onDatesChange }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const startCalendar = startOfWeek(startOfMonth(currentMonth));
  const endCalendar = endOfWeek(endOfMonth(currentMonth));
  const monthDaysArray = eachDayOfInterval({ start: startCalendar, end: endCalendar });


  const startDate = addDays(new Date(), 1);
  const endDate = addDays(new Date(), 60);


  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const isDateSelectable = date => isWithinInterval(date, { start: startDate, end: endDate });
  const isSelected = date => selectedDates.some(selectedDate => isSameDay(selectedDate, date));

  const toggleDate = date => {
    if (isSelected(date)) {
      setSelectedDates(selectedDates.filter(selectedDate => !isSameDay(selectedDate, date)));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  // useEffect(() => {
  //   // console.log('Selected Dates:', selectedDates.map(date => format(date, 'MM/dd/yyyy')));
  //   const formattedDates = selectedDates.map(date => format(date, 'MM/dd/yyyy'));
  //   onDatesChange(formattedDates);
  // }, [selectedDates, onDatesChange]);

  useEffect(() => {
    if (selectedDates.length > 0) {
      console.log('Selected Dates:', selectedDates.map(date => format(date, 'MM/dd/yyyy')));
      const formattedDates = selectedDates.map(date => format(date, 'MM/dd/yyyy'));
      onDatesChange(formattedDates);
    }
  }, ); // onDatesChange is now stable
  

  const handleNextMonth = (e) => {
    e.stopPropagation();
    setCurrentMonth(addMonths(currentMonth, 1));
  };
  
  const handlePrevMonth = (e) => {
    e.stopPropagation();
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  

  return (
    <div className="bg-gray-100 rounded-lg shadow p-4 relative max-w-lg mx-auto">
      <div className="flex justify-between items-center mb-2">
      <button type="button" onClick={(e) => handlePrevMonth(e)}>←</button>
        <span>{format(currentMonth, 'MMMM yyyy')}</span>
      <button type="button" onClick={(e) => handleNextMonth(e)}>→</button>
      </div>
      <div className="grid grid-cols-7 gap-1">
        {/* Weekday Labels */}
        {weekDays.map(day => (
          <div key={day} className="text-center font-bold">{day}</div>
        ))}

        {/* Calendar Days */}
        {monthDaysArray.map(day => (
          <button
          key={day}
          type="button"
          className={`p-2 rounded-full ${isSelected(day) ? 'bg-green-500 text-white' : (isDateSelectable(day) && format(day, 'MM') === format(currentMonth, 'MM') ? 'bg-white' : 'bg-gray-300')}`}
          disabled={!isDateSelectable(day) || format(day, 'MM') !== format(currentMonth, 'MM')}
          onClick={() => toggleDate(day)}
          style={!isDateSelectable(day) || format(day, 'MM') !== format(currentMonth, 'MM') ? { pointerEvents: 'none' } : null}
        >
            {format(day, 'd')}
          </button>
        ))}
      </div>
    </div>
  );
};


export default Calendar;










//            className={`p-2 rounded-full ${isDateSelectable(day) && format(day, 'MM') === format(currentMonth, 'MM') ? isSelected(day) ? 'bg-green-500 text-white' : 'bg-white' : 'bg-gray-300'}`}
