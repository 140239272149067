import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './FormUrlContainer.css'; // Importing CSS for styling

const FormUrlContainer = ({ auth }) => {
  const [formUrl, setFormUrl] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [isCheckingUrl, setIsCheckingUrl] = useState(false);
  const [isUrlTaken, setIsUrlTaken] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [isLoading, setIsLoading] = useState(false); 



  const generateRandomString = useCallback((length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }, []);

  const checkUrl = useCallback(async (url) => {
    try {
      const response = await axios.post('/api/user/finduser', { url });
      return response.data.user ? true : false;
    } catch (error) {
      console.error('Error checking URL:', error);
      return false;
    }
  }, []);

  const saveUrl = useCallback(async (url) => {
    try {
      await axios.post('/api/user/finduser', { url, user: auth.email });
    } catch (error) {
      console.error('Error saving URL:', error);
    }
  }, [auth.email]);

  const generateAndCheckRandomString = useCallback(async (length) => {
    let randomString = generateRandomString(length);
    let isTaken = await checkUrl(randomString);
    while (isTaken) {
      randomString = generateRandomString(length);
      isTaken = await checkUrl(randomString);
    }
    setFormUrl(randomString);
    await saveUrl(randomString);
  }, [generateRandomString, checkUrl, saveUrl]);

  useEffect(() => {
    const fetchAndSetUrl = async () => {
      try {
        const response = await axios.post('/api/user/finduser', { user: auth.email });
        if (response.data.url) {
          setFormUrl(response.data.url);
        } else {
          await generateAndCheckRandomString(7);
        }
      } catch (error) {
        console.error('Error fetching URL:', error);
      }
    };
  
    fetchAndSetUrl();
  }, [auth.email, generateAndCheckRandomString]);

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    if (/^[A-Za-z]*$/.test(newUrl)) { // Only allows letters
      setFormUrl(newUrl);
      setIsUrlTaken(false);
    }
  };

  const handleCheckUrl = async () => {
    setIsLoading(true); // Start loading
    setIsCheckingUrl(true);
  
    const isTaken = await checkUrl(formUrl);
    if (isTaken) {
      setIsUrlTaken(true);
    } else {
      await saveUrl(formUrl);
      setEditMode(false);
      setIsUrlTaken(false);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 5000); // Hide success message after 5 seconds
    }
  
    setIsCheckingUrl(false);
    setIsLoading(false); // End loading
  };
  

  return (
    <div className={`form-url-container ${isUrlTaken ? 'shake' : ''}`}>
      {isLoading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          {isUrlTaken && <p className="error-message">URL is already taken, try a different extension</p>}
          {showSuccessMessage && <p className="success-message">Successfully updated URL &#10003;</p>}
          <label>
            Form URL:
            <input type="text" value={formUrl} onChange={handleUrlChange} disabled={!editMode} />
            <a href={`https://scheduleclinic.com/newrequest-${formUrl}`} target="_blank" rel="noreferrer">
                https://scheduleclinic.com/newrequest-{formUrl}
            </a>
          </label>
          {editMode ? (
            <button onClick={handleCheckUrl} disabled={isCheckingUrl}>Check URL</button>
          ) : (
            <button onClick={() => setEditMode(true)}>Edit</button>
          )}
        </>
      )}
    </div>
  );
  
};

export default FormUrlContainer;
