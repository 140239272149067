
import React, { useState, useEffect } from 'react';
import { AuthState } from '../../context/AuthProvider';
import axios from 'axios';
import FormUrlContainer from './FormUrlContainer';

import DefaultSidebar from "../../components/sidebar"

const CustomPage = () => {
  const { auth } = AuthState();
  const [data, setData] = useState({
    header_logo: '',
    title: '',
    clinics: ['', ''],
    providers: [{ name: '', typeOfVisit: 'ALL' }],
    typeOfVisitOptions: [''] // New State for type of visit
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Added state variable to track submission status

  const handleProviderChange = (index, field, value) => {
    const updatedProviders = [...data.providers];
    updatedProviders[index] = { ...updatedProviders[index], [field]: value };
    setData({ ...data, providers: updatedProviders });
    setShowSaveButton(true);
  };

  const addProvider = () => {
    setData({
      ...data,
      providers: [...data.providers, { name: '', typeOfVisits: [] }]
    });
    setShowSaveButton(true);
  };

  const addTypeOfVisitOption = () => {
    setData({ ...data, typeOfVisitOptions: [...data.typeOfVisitOptions, ''] });
    setShowSaveButton(true);
  };

  const handleTypeOfVisitOptionChange = (index, value) => {
    const newOptions = [...data.typeOfVisitOptions];
    newOptions[index] = value;
    setData({ ...data, typeOfVisitOptions: newOptions });
    setShowSaveButton(true);
  };
  const handleProviderTypeChange = (providerIndex, typeIndex, value) => {
    const updatedProviders = [...data.providers];
    const selectedTypes = [...updatedProviders[providerIndex].typeOfVisits];
    selectedTypes[typeIndex] = value;
    updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], typeOfVisits: selectedTypes };
    setData({ ...data, providers: updatedProviders });
  };


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post('/api/user/customscript', {
          username: auth.bdsk
        }, {
          headers: { Authorization: `Bearer ${auth.token}` }
        });
        console.log(" this is the reponse from the ping", response);
        if (response.status === 200) {
          const updatedProviders = (response.data.providers || []).map(provider => ({
            ...provider,
            typeOfVisits: provider.typeOfVisits || [] // Ensure typeOfVisits is an array
          }));
  
          setData(prevData => ({
            ...prevData,
            header_logo: response.data.header_logo || prevData.header_logo,
            title: response.data.title || prevData.title,
            clinics: response.data.clinics || prevData.clinics, 
            providers: updatedProviders,
            typeOfVisitOptions: response.data.typeOfVisitOptions || prevData.typeOfVisitOptions
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [auth.bdsk, auth.token]);
  

  const handleInputChange = (name, value) => {
    setData({ ...data, [name]: value });
    setShowSaveButton(true);
  };

  const handleArrayChange = (name, index, value) => {
    const items = [...data[name]];
    items[index] = value;
    setData({ ...data, [name]: items });
    setShowSaveButton(true);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // Filter out empty strings from arrays and object fields
    const filteredClinics = data.clinics.filter(clinic => clinic.trim() !== '');
    const filteredTypeOfVisitOptions = data.typeOfVisitOptions.filter(option => option.trim() !== '');
    // Filter out providers that are empty or have an empty name field
    const filteredProviders = data.providers.filter(provider => provider.name.trim() !== '');

    // Ensure typeOfVisits for each provider is an array and also filter out any empty typeOfVisits
    const formattedProviders = filteredProviders.map(provider => ({
      ...provider,
      typeOfVisits: provider.typeOfVisits.filter(type => type.trim() !== '')
    }));

    const payload = {
      username: auth.bdsk,
      header_logo: data.header_logo.trim() ? data.header_logo : undefined,
      title: data.title.trim() ? data.title : undefined,
      clinics: filteredClinics,
      providers: formattedProviders,
      typeOfVisitOptions: filteredTypeOfVisitOptions
    };

    console.log('Submitting:', payload);

    try {
      await axios.post('/api/user/customscript', payload, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });

      setShowSaveButton(false);
      setIsSubmitted(true); // Set the submission status to true
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setIsLoading(false);
    }
  };
   

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
    <DefaultSidebar className="bg-gray-100" />
    <div className="flex-1 p-6 flex flex-col justify-start items-center">
      {/* Align items to the start */}
      <div className="w-full max-w-4xl"> {/* Set a maximum width */}
        <FormUrlContainer auth={auth} />
      </div>
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl mt-4">
        {/* Same maximum width for uniformity */}
        <div className="p-8 w-full">
          <form>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Logo URL:</label>
              <input type="text" value={data.header_logo} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => handleInputChange('header_logo', e.target.value)} />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Title: (optional)</label>
              <input type="text" value={data.title} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => handleInputChange('title', e.target.value)} />
            </div>
            <div className="mb-4">
              {/* Clinic input field */}
              <label className="block text-gray-700 text-sm font-bold mb-2">Clinics:</label>
              <input type="text" value={data.clinics[0]} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" onChange={(e) => handleArrayChange('clinics', 0, e.target.value)} />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Type of Visit Options:</label>
              {data.typeOfVisitOptions.map((option, index) => (
                <input key={index} type="text" value={option} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" onChange={(e) => handleTypeOfVisitOptionChange(index, e.target.value)} />
              ))}
              <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={addTypeOfVisitOption}>Add Type of Visit Option</button>
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Providers:</label>
            {data.providers.map((provider, providerIndex) => (
              <div key={providerIndex} className="flex flex-col mb-4">
                <input type="text" value={provider.name} className="mb-2" onChange={(e) => handleProviderChange(providerIndex, 'name', e.target.value)} />
                {data.typeOfVisitOptions.map((_, typeIndex) => (
                  <select key={typeIndex} value={provider.typeOfVisits[typeIndex] || ''} onChange={(e) => handleProviderTypeChange(providerIndex, typeIndex, e.target.value)}>
                    <option value="">Select Type of Visit</option>
                    {data.typeOfVisitOptions.map((option, optionIndex) => (
                      <option key={optionIndex} value={option}>{option}</option>
                    ))}
                  </select>
                ))}
              </div>
            ))}
              <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={addProvider}>Add Provider</button>
            </div>
            {showSaveButton && (
              <button type="button" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleSubmit}>Save</button>
            )}
            {isSubmitted && ( // Conditionally render the success message
              <div className="text-green-500 mt-2">You have been successfully subscribed.</div>
            )}
          </form>
        </div>
        </div>
        {isLoading && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
            <div>Loading...</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomPage;
